import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import ScrollTop from 'src/hooks/useScrollTop';
import { Provider } from 'react-redux';
import store from 'src/store';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import 'nprogress/nprogress.css';

export function AppWrapper() {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <SidebarProvider>
          <BrowserRouter>
            <ScrollTop />
            <AuthProvider>
              <App />
            </AuthProvider>
          </BrowserRouter>
        </SidebarProvider>
      </Provider>
    </HelmetProvider>
  );
}
